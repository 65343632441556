.body {
  font-family: Poppins !important;
}
.MuiTypography-root {
  font-family: Poppins;
}
.App {
  height: 100vh;
  /*font-size: calc(10px + 2vmin);*/
  font-family: Poppins;
}

.timer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.timer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-text {
  font-size: 2rem;
  transition: transform 0.3s ease; /* Adding a transition effect for sliding */
}

.timer-previous-text {
  font-size: 1rem;
  opacity: 0.5;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Adding transitions for sliding and opacity */
}

.animate {
  transform: translateY(-50px); /* Slide up animation */
}

.message-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.message-container.show {
  opacity: 1;
  visibility: visible;
}

.message-container.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.backgroundLogo {
  background-image: url("assets/img/HeaderDesktopImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 75px;
}
.MuiInputLabel-root.Mui-focused {
  color: #000000 !important;
}


@media screen and (max-width: 900px) {
  .backgroundLogo {
    background-image: url("assets/img/DarkeHeaderMobileNew.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 75px;
  }
}

@media screen and (max-width: 400px) {
 .main-container {
   width: 95%!important;
 }
}

